import React from "react";

const UnSubscribe = () => {
  return (
    <iframe
      width="540"
      height="490"
      src="https://ee0b570c.sibforms.com/serve/MUIFAHBoPjSoPVDkKyOO-IkGj0F9PeGh_UdDHaCUagz1qoFUFazDhNBiESD-Ly5-ni0qtOPuqzziYKenXNbkxVq05v-DbrSEOT4L0GSKVQTaqfD01ci7kZl441-59wDu3QhrvCb_eWhoLj7qXnip2jmYxvXdS7e32-MWKxCjyz2xfkdBYy8PoWIcGjLUBl4a3eQNxlefOwf1DuS2"
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
      }}
    ></iframe>
  );
};

export default UnSubscribe;
