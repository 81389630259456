import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SupportPartners.scss"; // Assuming you have a SCSS file for styling

const SupportPartners = () => {
  const [supportPartners, setSupportPartners] = useState([]);
  const [keyPartners, setKeyPartners] = useState([]);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch support partners from category 8
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=8`)
      .then((response) => {
        setSupportPartners(response.data);
        console.log("Support Partners:", response.data); // Log to see if data is correct
      })
      .catch((error) => {
        console.error("Error fetching support partners:", error);
      });

    // Fetch key partners from category 11
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=11`)
      .then((response) => {
        setKeyPartners(response.data);
        console.log("Key Partners:", response.data); // Log to see if data is correct
      })
      .catch((error) => {
        console.error("Error fetching key partners:", error);
      });
  }, [baseURL]);

  if (supportPartners.length === 0 && keyPartners.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="support-partners">
        {/* Only show the "Key Partners" section if data exists */}
        {keyPartners.length > 0 && (
          <>
            <h2>Key Partners</h2>
            <div className="partners-grid">
              {keyPartners.map((keypartner) => (
                <a href={keypartner.acf.support_partner_website_url}>
                  <div key={keypartner.id} className="partner-card">
                    <img
                      src={keypartner.acf.support_partner_logo}
                      alt={keypartner.acf.support_partner_logo_alt_tag}
                      title={keypartner.acf.support_partner_logo_title_tag}
                    />
                    <p>{keypartner.acf.support_partner_business_name}</p>
                  </div>
                </a>
              ))}
            </div>
          </>
        )}

        {/* Only show the "Support Partners" section if data exists */}
        {supportPartners.length > 0 && (
          <>
            <h2>Support Partners</h2>
            <div className="partners-grid">
              {supportPartners.map((partner) => (
                <a href={partner.acf.support_partner_website_url}>
                  <div key={partner.id} className="partner-card">
                    <img
                      src={partner.acf.support_partner_logo}
                      alt={partner.acf.support_partner_logo_alt_tag}
                      title={partner.acf.support_partner_logo_title_tag}
                    />
                    <p>{partner.acf.support_partner_business_name}</p>
                  </div>
                </a>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SupportPartners;
