import React from "react";
import UnSubscribe from "../../Components/Forms/Unsubscribe";
import "./Unsubscribe.scss";

const UnSubscribePage = () => {
  return (
    <>
      <div className="unsubscribe-intro">
        <h1>Unsubscribe</h1>
        <p>
          For any quiries, please email{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            contact@techwestenglandadvocates.co.uk
          </a>
          .
        </p>
      </div>
      <div className="form-container-unsubscribe">
        <UnSubscribe />
      </div>
    </>
  );
};

export default UnSubscribePage;
