const Subscribe = () => {
  return (
    <iframe
      width="540"
      height="1250"
      src="https://ee0b570c.sibforms.com/serve/MUIFAAkJNVD5--qanxY5QJ7f5JsZEosLzfTAdh97W8lQi8hgKfvdEuJnKlV3uiFmjqgLR9J8plON1Zbo2G0lMORQisVdXeeO8hrUjlWlibMZhxhW_72CRNwNyqNW2RUutqucqTu9QzN9mPKRRSKEix7YlMGesJEhVPPY6j3wLdGvpm0QDF9_3n8Ez9ikwlZoXleUYojARegyYNrP"
      frameBorder="0"
      scrolling="auto"
      allowFullScreen
      style={{
        display: "block",
        maxWidth: "100%",
      }}
    ></iframe>
  );
};

export default Subscribe;
