import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./Team.scss"; // Assuming you have some styling for this component
import linkedin from "../../Media/Images/Icons/linkedin-icon.svg";
import { Link } from "react-router-dom";

const Team = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [advisoryBoard, setAdvisoryBoard] = useState([]);
  const [ambassadors, setAmbassadors] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("advisory");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // To track if there are more posts to load
  const [loading, setLoading] = useState(false); // To prevent multiple fetches

  const perPage = 18;

  // Ref for the observer
  const observer = useRef();

  // Fetch the team members based on the category and page
  useEffect(() => {
    setLoading(true);
    const categoryID = selectedCategory === "advisory" ? 5 : 6;

    axios
      .get(
        `${baseURL}/wp-json/wp/v2/posts?categories=${categoryID}&_embed&per_page=${perPage}&page=${currentPage}`
      )
      .then((response) => {
        let newMembers = response.data;

        if (selectedCategory === "ambassadors") {
          // Find "Russel Britton" and move him to the start of the array
          const russelBrittonIndex = newMembers.findIndex(
            (member) => member.acf.name === "Russel Britton"
          );

          let russelBritton = null;
          if (russelBrittonIndex !== -1) {
            // Remove Russel Britton from the array
            russelBritton = newMembers.splice(russelBrittonIndex, 1)[0];
          }

          // Sort the remaining members alphabetically by surname (last name)
          newMembers = newMembers.sort((a, b) => {
            const aLastName = a.acf.name.split(" ").pop().toLowerCase();
            const bLastName = b.acf.name.split(" ").pop().toLowerCase();
            return aLastName.localeCompare(bLastName);
          });

          // If Russel Britton is found, move him to the top of the sorted array
          if (russelBritton) {
            newMembers.unshift(russelBritton);
          }

          if (currentPage === 1) {
            setAmbassadors(newMembers); // Set Ambassadors with Russel Britton at the start
          } else {
            setAmbassadors((prev) => [...prev, ...newMembers]);
          }
        } else {
          if (currentPage === 1) {
            setAdvisoryBoard(newMembers); // Set Advisory Board members
          } else {
            setAdvisoryBoard((prev) => [...prev, ...newMembers]);
          }
        }

        // If there are no more posts to fetch, set hasMore to false
        setHasMore(newMembers.length === perPage);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching team members:", error);
        setLoading(false);
      });
  }, [baseURL, currentPage, selectedCategory]);

  // Infinite Scroll Observer
  useEffect(() => {
    if (loading) return;
    if (!hasMore) return;

    const currentElement = observer.current;
    const observerInstance = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setCurrentPage((prevPage) => prevPage + 1); // Load next page
        }
      },
      { threshold: 1.0 }
    );

    if (currentElement) {
      observerInstance.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observerInstance.unobserve(currentElement);
      }
    };
  }, [loading, hasMore]);

  const membersToDisplay =
    selectedCategory === "advisory" ? advisoryBoard : ambassadors;

  return (
    <div className="team-page">
      <h1>Team</h1>
      <div className="button-group">
        <button
          className={`toggle-btn ${
            selectedCategory === "advisory" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedCategory("advisory");
            setCurrentPage(1); // Reset to page 1
            setAdvisoryBoard([]); // Clear previous advisory board data
            setHasMore(true); // Reset the "has more" state when switching categories
          }}
        >
          Advisory Board
        </button>
        <button
          className={`toggle-btn ${
            selectedCategory === "ambassadors" ? "active" : ""
          }`}
          onClick={() => {
            setSelectedCategory("ambassadors");
            setCurrentPage(1); // Reset to page 1
            setAmbassadors([]); // Clear previous ambassador data
            setHasMore(true); // Reset the "has more" state when switching categories
          }}
        >
          Ambassadors
        </button>
      </div>

      {/* Render members */}
      <div className="members-grid">
        {membersToDisplay.map((member) => (
          <div key={member.id} className="member-card">
            <Link to={`/team/${member.slug}`}>
              <img
                src={member.acf.profile_picture}
                alt={member.acf.image_alt_tag}
                title={member.acf.image_title_tag}
              />
              <h3>{member.acf.name}</h3>
              <p>{member.acf.role}</p>
            </Link>

            {member.acf.linkedin_url && (
              <div className="linkedin-icon">
                <a
                  href={member.acf.linkedin_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Loading and observer */}
      {loading && <p></p>}
      <div ref={observer} />
    </div>
  );
};

export default Team;
