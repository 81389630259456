import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Events.scss";

const Events = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [acfFields, setAcfFields] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [previousEvents, setPreviousEvents] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts/466`)
      .then((response) => {
        setAcfFields(response.data.acf);
      })
      .catch((error) => {
        console.error("Error fetching ACF fields:", error);
      });

    // Fetch events from category 10
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=10&_embed`)
      .then((response) => {
        const currentDate = new Date();
        const events = response.data;

        const upcoming = [];
        const previous = [];

        events.forEach((event) => {
          const eventStartDate = new Date(
            event.acf.event_start_date.slice(0, 4),
            event.acf.event_start_date.slice(4, 6) - 1,
            event.acf.event_start_date.slice(6, 8)
          );

          if (eventStartDate >= currentDate) {
            upcoming.push(event);
          } else {
            previous.push(event);
          }
        });

        upcoming.sort(
          (a, b) =>
            new Date(a.acf.event_start_date) - new Date(b.acf.event_start_date)
        );

        previous.sort(
          (a, b) =>
            new Date(b.acf.event_start_date) - new Date(a.acf.event_start_date)
        );

        setUpcomingEvents(upcoming);
        setPreviousEvents(previous);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, [baseURL]);

  // Function to format the date from "YYYYMMDD" to "DD MMM YYYY"
  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(year, month - 1, day).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  if (
    !acfFields &&
    upcomingEvents.length === 0 &&
    previousEvents.length === 0
  ) {
    return <div></div>;
  }

  return (
    <>
      {acfFields && (
        <div className="home">
          <div className="content">
            <div className="text-container">
              <h1>{acfFields.page_title}</h1>
              <p>{acfFields.accompanying_text_under_page_title}</p>
            </div>
          </div>
          <div className="image">
            <img
              src={acfFields.page_title_image}
              alt={acfFields.page_title_image_alt_tag}
              title={acfFields.page_title_image_title_tag}
            />
          </div>
        </div>
      )}

      <div className="events-page">
        <h2>Upcoming Events</h2>
        <div className="events-grid">
          {upcomingEvents.map((event) => (
            <div key={event.id} className="event-card">
              {/* Link to event details page */}
              <Link to={`/events/${event.slug}`}>
                <img
                  src={event.acf.event_image} // Adjust based on your ACF field
                  alt={event.acf.event_image_alt_tag} // Adjust based on your ACF field
                  title={event.acf.event_image_title_tag} // Adjust based on your ACF field
                />
                <h3>{event.acf.event_name}</h3>
                <p>{event.acf.event_excerpt}</p>
                <p>
                  <strong>Date:</strong>{" "}
                  {formatDate(event.acf.event_start_date)}
                </p>
              </Link>
            </div>
          ))}
        </div>

        {previousEvents.length > 0 && (
          <>
            <h2>Previous Events</h2>
            <div className="events-grid">
              {previousEvents.map((event) => (
                <div key={event.id} className="event-card">
                  <Link to={`/events/${event.slug}`}>
                    <img
                      src={event.acf.event_image} // Adjust based on your ACF field
                      alt={event.acf.event_image_alt_tag} // Adjust based on your ACF field
                      title={event.acf.event_image_title_tag} // Adjust based on your ACF field
                    />
                    <h3>{event.acf.event_name}</h3>
                    <p>{event.acf.event_excerpt}</p>
                    <p>
                      <strong>Date:</strong>{" "}
                      {formatDate(event.acf.event_start_date)}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Events;
