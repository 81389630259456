import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./NewsDetails.scss"; // Assuming you have some styling for this component

const NewsDetails = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [event, setEvent] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the event details based on the slug
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then((response) => {
        setEvent(response.data[0]); // Assuming response data is an array
      })
      .catch((error) => {
        console.error("Error fetching event details:", error);
      });
  }, [slug, baseURL]);

  if (!event) {
    return <div></div>;
  }

  return (
    <div className="news-details">
      <h1>{event.acf.article_title}</h1>
      <img
        className="header-image"
        src={event.acf.article_image}
        alt={event.acf.article_image_alt_tag}
        title={event.acf.article_image_title_tag}
      />

      <div className="event-description">
        <div
          dangerouslySetInnerHTML={{
            __html: event.acf.article_content_container,
          }}
        />
      </div>
    </div>
  );
};

export default NewsDetails;
