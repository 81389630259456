import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./News.scss";

const News = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [acfFields, setAcfFields] = useState(null);
  const [newsFields, setNewsFields] = useState([]); // Initialize as an empty array

  useEffect(() => {
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts/623`)
      .then((response) => {
        setAcfFields(response.data.acf);
      })
      .catch((error) => {
        console.error("Error fetching ACF fields:", error);
      });

    // Fetch news from category 3
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=3&_embed`)
      .then((news) => {
        setNewsFields(news.data); // Set the entire posts array
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  }, [baseURL]);

  if (!acfFields) {
    return <div></div>;
  }

  return (
    <>
      {acfFields && (
        <div className="home">
          <div className="content">
            <div className="text-container">
              <h1>{acfFields.page_title}</h1>
              <p>{acfFields.accompanying_text_under_page_title}</p>
            </div>
          </div>
          <div className="image">
            <img
              src={acfFields.page_title_image}
              alt={acfFields.page_title_image_alt_tag}
              title={acfFields.page_title_image_title_tag}
            />
          </div>
        </div>
      )}

      {/* Only render the Latest News section if there are news items */}
      {newsFields.length > 0 && (
        <div className="news-page">
          <h2>Latest News</h2>
          <div className="news-grid">
            {newsFields.map((news) => (
              <div key={news.id} className="news-card">
                <Link to={`/news/${news.slug}`}>
                  <img
                    src={news.acf.article_image} // Access the ACF field inside each post
                    alt={news.acf.article_image_alt_tag} // Access the alt tag
                    title={news.acf.article_image_title_tag} // Access the title tag
                  />
                  <h3>{news.acf.article_title}</h3>
                  <p>{news.acf.article_excerpt}</p>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="subscribe-section">
        <p>
          Subscribe to our newsletter to keep up to date with the latest news
          and events.
        </p>
        <Link to="/join">
          <p className="primary-btn">Join</p>
        </Link>
      </div>
    </>
  );
};

export default News;
