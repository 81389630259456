import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Join.scss";
import Subscribe from "../../Components/Forms/Subscribe";
import linkedin from "../../Media/Images/Icons/linkedin-icon.svg";
import { Link } from "react-router-dom";

const Join = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [acfFields, setAcfFields] = useState(null);

  useEffect(() => {
    // Fetch the ACF fields from the WordPress REST API
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts/627`)
      .then((response) => {
        setAcfFields(response.data.acf);
      })
      .catch((error) => {
        console.error("Error fetching ACF fields:", error);
      });
  }, []);

  if (!acfFields) {
    return <div></div>;
  }

  return (
    <>
      <div className="join">
        <h1>{acfFields.page_title}</h1>
        <p>
          For any inquiries please email{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            contact@techwestenglandadvocates.co.uk
          </a>
          .
        </p>
        <div className="form-and-image-container">
          <div className="form-container">
            <h2>Subscribe for News Updates</h2>
            <div className="form">
              <Subscribe />
            </div>
          </div>
          <div className="image-container">
            <h2>
              Join our LinkedIn Group
              <span className="linkedin-icon">
                <a href="https://www.linkedin.com/groups/9075050/">
                  <img src={linkedin}></img>
                </a>
              </span>
            </h2>
            <div className="image">
              <img
                src={acfFields.page_title_image}
                alt={acfFields.page_title_image_alt_tag}
                title={acfFields.page_title_image_title_tag}
              />
            </div>
          </div>
        </div>
        <p>
          To unsubscribe from receiving news updates, please{" "}
          <Link to="/unsubscribe">click here</Link>.
        </p>
      </div>
    </>
  );
};

export default Join;
