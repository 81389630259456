import xicon from "../../Media/Images/Icons/x-icon.svg";
import linkedin from "../../Media/Images/Icons/linkedin-icon.svg";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="site-container">
        <div className="footer-container">
          <div className="col-1">
            <a href="mailto:contact@techwestenglandadvocates.co.uk">
              <p>Contact Us</p>
            </a>
          </div>
          <div className="col-2">
            <a href="https://twitter.com/TechSWEAdv">
              <img className="x-logo" src={xicon}></img>
            </a>
            <a href="https://www.linkedin.com/groups/9075050/">
              <img className="x-logo" src={linkedin}></img>
            </a>
          </div>
          <div className="col-3">
            <p>
              <a href="https://www.globaltechadvocates.org/privacy-policy/">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="details">
        <p className="address">
          <b>Future Space</b>, UWE North Gate, Filton Road, Stoke Gifford,
          Bristol, BS34 8RB
        </p>
        <p className="phone">
          <b>Tel</b> <a href="tell:4403300040868">+44 (0) 330 004 0868</a>
        </p>
        <p className="email">
          {" "}
          <b>Email</b>{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            contact@techwestenglandadvocates.co.uk
          </a>
        </p>
        <p className="company-no">
          <b>Company no.</b> 14599403
        </p>
        <p className="like-the-site">
          <b>Like the site?</b>{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            Contact us here to explore all digital design solutions available
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
