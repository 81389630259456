import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Home.scss";
import SupportPartners from "../../Components/SupportPartners/SupportPartners";
import { Link } from "react-router-dom";

const Home = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [acfFields, setAcfFields] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextEvent, setNextEvent] = useState(null); // State for next upcoming event

  useEffect(() => {
    // Fetch the ACF fields from the WordPress REST API
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts/289`)
      .then((response) => {
        setAcfFields(response.data.acf);
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching data. Please try again later.");
        setLoading(false);
      });

    // Fetch upcoming events from category 10 (replace '10' with your event category ID)
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=10&_embed`)
      .then((response) => {
        const events = response.data;

        // Filter and sort events to find the next upcoming one
        const currentDate = new Date();
        const upcomingEvents = events.filter((event) => {
          const eventStartDate = new Date(
            event.acf.event_start_date.slice(0, 4),
            event.acf.event_start_date.slice(4, 6) - 1,
            event.acf.event_start_date.slice(6, 8)
          );
          return eventStartDate >= currentDate;
        });

        // Sort upcoming events by closest date
        upcomingEvents.sort((a, b) => {
          const dateA = new Date(
            a.acf.event_start_date.slice(0, 4),
            a.acf.event_start_date.slice(4, 6) - 1,
            a.acf.event_start_date.slice(6, 8)
          );
          const dateB = new Date(
            b.acf.event_start_date.slice(0, 4),
            b.acf.event_start_date.slice(4, 6) - 1,
            b.acf.event_start_date.slice(6, 8)
          );
          return dateA - dateB;
        });

        // Set the next upcoming event if any exist
        if (upcomingEvents.length > 0) {
          setNextEvent(upcomingEvents[0]); // Set the next upcoming event
        }
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, [baseURL]);

  // Format the event date
  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(year, month - 1, day).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  if (loading) {
    return <div></div>; // Loading state
  }

  if (error) {
    return <div>{error}</div>; // Error message if fetch fails
  }

  return (
    <>
      {/* First section */}
      <div className="home">
        <div className="content">
          <div className="text-container">
            <h1>{acfFields.page_title}</h1>
            <p>{acfFields.accompanying_text_under_page_title}</p>
          </div>
        </div>
        <div className="image">
          <img
            src={acfFields.page_title_image}
            alt={acfFields.page_title_image_alt_tag}
            title={acfFields.page_title_image_title_tag}
          />
        </div>
      </div>

      {/* Second section */}
      <div className="home">
        <div className="image">
          <img
            src={acfFields.secondary_title_image}
            alt={acfFields.secondary_title_image_alt_tag}
            title={acfFields.secondary_title_image_title_tag}
          />
        </div>
        <div className="content">
          <div className="text-container">
            <h1>{acfFields.secondary_title}</h1>
            <p>{acfFields.accompanying_text_under_secondary_title}</p>
          </div>
        </div>
      </div>

      {/* Upcoming Event Section */}
      {nextEvent && (
        <div className="upcoming-event-section-container">
          <div className="upcoming-event-section">
            <h2>Next Event</h2>
            <a href={`/events/${nextEvent.slug}`}>
              <div className="event-card">
                <img
                  src={nextEvent.acf.event_image}
                  alt={nextEvent.acf.event_image_alt_tag}
                  title={nextEvent.acf.event_image_title_tag}
                />
                <h3>{nextEvent.acf.event_name}</h3>
                <p>{nextEvent.acf.event_excerpt}</p>
                <p>
                  <strong>Date:</strong>{" "}
                  {formatDate(nextEvent.acf.event_start_date)}
                </p>
              </div>
            </a>
            <Link to="/events">
              <a className="primary-btn">View All Events</a>
            </Link>
          </div>
        </div>
      )}

      {/* Support Partners Section */}
      <SupportPartners />
    </>
  );
};

export default Home;
