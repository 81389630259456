import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./SearchBar.scss";

const SearchBar = ({ newsItems = [], eventItems = [] }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const searchBarRef = useRef(null); // Ref to the search bar container

  useEffect(() => {
    // Log news and event items to see if they are being passed correctly
    console.log("News Items:", newsItems);
    console.log("Event Items:", eventItems);
  }, [newsItems, eventItems]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchTerm(query);

    // If search term is empty, clear results
    if (query.trim() === "") {
      setSearchResults([]);
      return;
    }

    // Combine news and events into one array
    const combinedItems = [
      ...(newsItems || []).map((item) => ({ ...item, type: "news" })),
      ...(eventItems || []).map((item) => ({ ...item, type: "event" })),
    ];

    // Filter items based on search query
    const results = combinedItems.filter((item) => {
      const title = item.acf?.article_title || item.acf?.event_name;
      return title?.toLowerCase().includes(query);
    });

    setSearchResults(results);
  };

  const highlightMatch = (text, query) => {
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, i) => (
      <span
        key={i}
        style={
          part.toLowerCase() === query.toLowerCase()
            ? { backgroundColor: "#ff9f8c" }
            : {}
        }
      >
        {part}
      </span>
    ));
  };

  const handleClickOutside = (event) => {
    // Check if the click is outside of the search bar and results
    if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
      setSearchResults([]); // Clear search results
    }
  };

  const clearSearchResults = () => {
    // Clear search results when a search result is clicked
    setSearchResults([]);
    setSearchTerm(""); // Optionally, clear the search input as well
  };

  useEffect(() => {
    // Add event listener for clicks on the document
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="search-bar" ref={searchBarRef}>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {searchResults.length > 0 && (
        <div className="search-results">
          <ul>
            {searchResults.map((result) => (
              <li key={result.id}>
                <Link
                  to={`/${result.type === "event" ? "events" : "news"}/${
                    result.slug
                  }`}
                  onClick={clearSearchResults} // Clear results when an item is clicked
                >
                  {highlightMatch(
                    result.acf?.article_title || result.acf?.event_name,
                    searchTerm
                  )}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
