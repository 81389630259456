// import logo from './logo.svg';
import "./App.scss";
import TopNav from "./Components/TopNav/TopNav";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./_Pages/Home/Home";
import Events from "./_Pages/Events/Events";
import News from "./_Pages/News/News";
import Join from "./_Pages/Join/Join";
import Footer from "./Components/Footer/Footer";
import EventDetails from "./Components/EventDetails/EventDetails";
import NewsDetails from "./Components/NewsDetails/NewsDetails";
import Team from "./_Pages/Team/Team";
import TeamDetails from "./Components/TeamDetails/TeamDetails";
import UnsubscribePage from "./_Pages/Unsubscribe/UnsubscribePage";

function App() {
  return (
    <Router>
      <TopNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/team" element={<Team />} />
        <Route path="/team/:slug" element={<TeamDetails />} />{" "}
        <Route path="/events" element={<Events />} />
        <Route path="/events/:slug" element={<EventDetails />} />{" "}
        <Route path="/news" element={<News />} />
        <Route path="/news/:slug" element={<NewsDetails />} />{" "}
        <Route path="/join" element={<Join />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
