import React, { useEffect, useState } from "react";
import logo from "../../../src/logo.svg";
import "./TopNav.scss";
import { Link } from "react-router-dom";
import SearchBar from "../SearchBar/SearchBar";
import axios from "axios";

const TopNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [newsItems, setNewsItems] = useState([]);
  const [eventItems, setEventItems] = useState([]);

  // Fetch News
  useEffect(() => {
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=3&_embed`)
      .then((response) => {
        setNewsItems(response.data); // Fetch news and store in state
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  }, [baseURL]);

  // Fetch Events
  useEffect(() => {
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?categories=10&_embed`)
      .then((response) => {
        setEventItems(response.data); // Fetch events and store in state
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, [baseURL]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`top-nav ${scrolled ? "scrolled" : ""}`}>
      <div className="site-container">
        <div className="nav-container">
          <div className="logo">
            <img src={logo} alt="Tech West England Advocates Logo" />
          </div>
          <SearchBar newsItems={newsItems} eventItems={eventItems} />
          <div className="menu-icon" onClick={toggleMenu}>
            {/* Hamburger icon or cross based on state */}
            <span className={menuOpen ? "cross" : "hamburger"}></span>
          </div>
          <div className={`nav-links ${menuOpen ? "open" : ""}`}>
            <nav>
              <ul>
                <li>
                  <Link to="/" onClick={toggleMenu}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/team" onClick={toggleMenu}>
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/events" onClick={toggleMenu}>
                    Events
                  </Link>
                </li>
                <li>
                  <Link to="/news" onClick={toggleMenu}>
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/join" onClick={toggleMenu}>
                    Join
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.globaltechadvocates.org/launch-pad/"
                    onClick={toggleMenu}
                  >
                    GTA Launch Pad
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNav;
